<template>
  <div v-show="show">
    <questions
      :currentQuestion="currentQuestion"
      @nextQuestion="checkNextQuestion($event.question)"
      @previousQuestion="changeToPrevious($event)"
      :currentStep="step"
    ></questions>
  </div>
</template>

<script>
import questions from './questions/questions.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'quiz',
  components: {
    questions,
  },
  data() {
    return {
      show: false,
      step: 1,
      currentQuestion: null,
      listQuestions: [
        {
          step: 1,
          name: 'q9',
          dataSlot: {},
          componentName: 'q9.vue',
          previous: null,
          component: null,
        },
        {
          step: null,
          name: 'q1',
          dataSlot: {},
          componentName: 'q1.vue',
          previous: null,
          component: null,
        },
        {
          step: null,
          name: 'q2',
          dataSlot: {},
          componentName: 'q2.vue',
          previous: null,
          component: null,
        },
        {
          step: null,
          name: 'q3',
          dataSlot: {},
          componentName: 'q3.vue',
          previous: null,
          component: null,
        },
        {
          step: null,
          name: 'q4',
          dataSlot: {},
          componentName: 'q4.vue',
          previous: null,
          component: null,
        },
        {
          step: null,
          name: 'q5',
          dataSlot: {},
          componentName: 'q5.vue',
          previous: null,
          component: null,
        },
        {
          step: null,
          name: 'q6',
          dataSlot: {},
          componentName: 'q6.vue',
          previous: null,
          component: null,
        },
        {
          step: null,
          name: 'q7',
          dataSlot: {},
          componentName: 'q7.vue',
          previous: null,
          component: null,
        },
        {
          step: null,
          name: 'q8',
          dataSlot: {},
          componentName: 'q8.vue',
          previous: null,
          component: null,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      formUser: 'formDataUser/formUser',
    }),
  },
  async mounted() {
    await this.$store.dispatch('formDataUser/getFormUser')
    this.loadingQuestions()
    this.show = true
  },
  methods: {
    firstQuestion() {
      this.pushCurrentQuestion('q9', 1, 'first')
    },
    loadingQuestions() {
      console.log(this.formUser.lastQuest)
      if (!this.formUser.lastQuest) {
        this.firstQuestion()
      } else {
        switch (this.formUser.typeOfRegistration.value) {
          case 1:
            this.openingQuestions({ name: this.formUser.lastQuest })
            break
          case 2:
            this.migrationQuestions({ name: this.formUser.lastQuest })
            break
          case 3:
            this.transformationQuestions({ name: this.formUser.lastQuest })
            break
          case 6:
            this.autonomousQuestions({ name: this.formUser.lastQuest })
            break
        }
      }
    },
    checkNextQuestion(question) {
      switch (parseInt(this.formUser.typeOfRegistration.value)) {
        case 1:
          this.openingQuestions(question)
          break
        case 2:
          this.migrationQuestions(question)
          break
        case 3:
          this.transformationQuestions(question)
          break
        case 6:
          this.autonomousQuestions(question)
          break
      }
    },
    openingQuestions(question) {
      switch (question.name) {
        case 'q9':
          this.pushCurrentQuestion('q1', 2, 'first')
          break
        case 'q1':
          this.pushCurrentQuestion('q2', 3, 'q9')
          break
        case 'q2':
          this.pushCurrentQuestion('q3', 4, 'q1')
          break
        case 'q3':
          this.pushCurrentQuestion('q4', 5, 'q2')
          break
        case 'q4':
          this.pushCurrentQuestion('q6', 6, 'q3')
          break
        case 'q5':
        case 'q6':
          this.goToLestResumeOrFinishingQuiz()
          break
        default:
          break
      }
    },
    migrationQuestions(question) {
      switch (question.name) {
        case 'q9':
          this.pushCurrentQuestion('q1', 2, 'first')
          break
        case 'q1':
          this.pushCurrentQuestion('q2', 3, 'q9')
          break
        case 'q2':
          this.pushCurrentQuestion('q7', 4, 'q1')
          break
        case 'q7':
          this.pushCurrentQuestion('q8', 5, 'q2')
          break
        case 'q8':
          this.pushCurrentQuestion('q5', 6, 'q7')
          break
        case 'q5':
          this.goToLestResumeOrFinishingQuiz()
          break
        default:
          break
      }
    },
    transformationQuestions(question) {
      switch (question.name) {
        case 'q9':
          this.pushCurrentQuestion('q2', 2, 'first')
          break
        case 'q2':
          this.pushCurrentQuestion('q7', 3, 'q9')
          break
        case 'q7':
          this.pushCurrentQuestion('q5', 4, 'q2')
          break
        case 'q5':
          this.goToLestResumeOrFinishingQuiz()
          break
        default:
          break
      }
    },
    autonomousQuestions(question) {
      switch (question.name) {
        case 'q9':
          this.pushCurrentQuestion('q2', 2, 'first')
          break
        case 'q2':
          this.pushCurrentQuestion('q4', 3, 'q9')
          break
        case 'q4':
          this.pushCurrentQuestion('q6', 4, 'q2')
          break
        case 'q6':
          this.goToLestResumeOrFinishingQuiz()
          break
        default:
          break
      }
    },
    pushCurrentQuestion(name, step, previous) {
      this.step = step
      let question = Object.assign(
        {},
        this.listQuestions.find((item) => item.name == name)
      )
      question.step = step
      question.previous = previous
      question.component = () => import(`./questions/${question.componentName}`)
      this.currentQuestion = question
    },
    changeToPrevious(question) {
      let copyQuestion = Object.assign({}, question)
      if (copyQuestion.previous === 'first') {
        this.firstQuestion()
      } else {
        copyQuestion.name = copyQuestion.previous
        this.checkNextQuestion(copyQuestion)
      }
    },
    goToLestResumeOrFinishingQuiz() {
      this.$store.dispatch('formDataUser/goToNextStep')
      this.$router.push({ name: 'finishingQuiz' })
    },
  },
}
</script>

<style lang="scss"></style>
