<template>
  <v-stepper :value="step" alt-labels class="remove-borde-background">
    <v-stepper-items>
      <v-stepper-content
        class="pa-0 ma-0"
        v-for="quiz in questions"
        :key="quiz.step"
        :step="quiz.step"
      >
        <v-form :ref="`form-${quiz.name}`" v-model="valid">
          <v-row>
            <v-col cols="12">
              <quiz.component
                :is="quiz.component"
                v-if="quiz.component"
                @onAnswer="addAnswer($event, quiz)"
                :isValid="validation"
                style="min-height: 200px;"
              />
            </v-col>
          </v-row>
          <v-row justify="space-between" class="my-1">
            <v-spacer />

            <v-btn
              class="mx-3 custom-btn-green-inverse rounded-0 btn-large"
              v-if="quiz.step != 1"
              @click="previousQuestion(quiz)"
              :loading="loadingPrevious"
            >
              voltar
            </v-btn>
            <v-btn
              class="custom-btn-green rounded-0 btn-large"
              @click="changeStep(quiz)"
              :loading="loadingNext"
            >
              próxima
            </v-btn>
            <v-spacer />
          </v-row>
        </v-form>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
export default {
  props: {
    currentQuestion: {
      type: Object,
      require: true,
      default: {},
    },
    currentStep: {
      type: Number,
      require: true,
      default: 1,
    },
  },
  data() {
    return {
      valid: true,
      loadingPrevious: false,
      loadingNext: false,
      questions: [],
      step: 0,
      validation: null,
    }
  },
  methods: {
    async changeStep(value) {
      try {
        let nameRef = `form-${value.name}`
        this.validation = this.$refs[nameRef][0].validate()
        if (this.loadingNext) return
        if (!this.validation) return
        let data = {
          ...value.answer,
          lastquest: value.name,
        }
        this.loadingNext = true
        await this.$store.dispatch('formDataUser/updatedFormUser', data)
        this.loadingNext = false
        this.$emit('nextQuestion', { question: value })
      } catch (error) {
        this.loadingNext = false
      }
    },
    addQuestions(value) {
      let findIndex = this.questions.find((item, index) => {
        if (item.step == value.step) return index
      })
      this.questions.splice(findIndex, 1)
      let question = this.questions.find((item) => item.name == value.name)
      if (!question) this.questions.push(value)
      setTimeout(() => {
        this.step = this.currentStep
      }, 120)
    },
    addAnswer(value, quiz) {
      this.questions.filter((item, index) => {
        if (item.step == quiz.step) {
          item.answer = value
        }
      })
    },
    previousQuestion(question) {
      this.loadingPrevious = true
      this.valid = true
      let findQ = this.questions.find((item) => item.name == question.previous)
      if (findQ) {
        --this.step
      } else {
        this.$emit('previousQuestion', question)
      }
      let findIndex = this.questions.find((item, index) => {
        if (item.step == question.step) return index
      })
      this.questions.splice(findIndex, 1)
      setTimeout(() => {
        this.loadingPrevious = false
      }, 120)
    },
  },
  mounted() {},
  watch: {
    currentQuestion: {
      handler: function(val, oldVal) {
        if (val) this.addQuestions(val)
      },
      deep: true,
    },
  },
}
</script>
<style scoped lang="scss">
@import '@design';
</style>
